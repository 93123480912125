import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';

// Combine multiple reducers if needed
const rootReducer = combineReducers({
  auth: authReducer
});

// Configure middleware
const middleware = [thunk];

// Create the Redux store
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
