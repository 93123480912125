import React, { useEffect, useState } from 'react';
import { doc, updateDoc, arrayUnion, getDoc, collection, addDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../../api/firebase';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import { notify } from '../../Utils/notification';

const Parts = () => {
  const [parts, setParts] = useState([]);
  const [open, setOpen] = useState(false);
  const [editPartId, setEditPartId] = useState(null);
  const [partName, setPartName] = useState('');
  const [partThumbnail, setPartThumbnail] = useState('');
  const [selectedPart, setSelectedPart] = useState(null);
  const [search, setSearch] = useState(null);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // Get all parts from Firestore (async function)
    getParts();
  }, []);

  const getParts = async () => {
    const partsCollectionRef = collection(db, 'parts');
    const partsSnapshot = await getDocs(partsCollectionRef);
    const partsList = partsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setParts(partsList);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setEditPartId(id);
    const part = parts.find((part) => part.id === id);
    setPartName(part ? part.name : '');
    setPartThumbnail(part ? part.thumbnail : '');
  };

  const handleClose = () => {
    setOpen(false);
    setEditPartId(null);
    setPartName('');
    setPartThumbnail('');
    setSelectedPart(null);
  };

  const handleDelete = (id) => {
    setConfirmDeleteId(id);
  };

  const handleConfirmDelete = async () => {
    // Delete the part
    await deletePart(confirmDeleteId);
    // Update the parts state or perform any necessary cleanup
    setParts(parts.filter((part) => part.id !== confirmDeleteId));
    // Reset the confirmDeleteId state
    setConfirmDeleteId(null);
  };

  const deletePart = async (partId) => {
    try {
      const partDocRef = doc(db, 'parts', partId);
      await deleteDoc(partDocRef);

      console.log('Part deleted successfully!');
      notify('success', 'Part deleted successfully', partId);
      // You can optionally handle success or display a success message here
    } catch (error) {
      console.error('Error deleting part: ', error);
      // You can handle errors or display an error message here
    }
  };

  const handleSave = () => {
    const existingPart = parts.find((part) => part.name === partName);

    if (existingPart && existingPart.id !== editPartId) {
      notify('danger', 'Error creating part', 'Part with the same name already exists! Name must be unique.');
      return;
    }

    if (editPartId) {
      // Edit the part
      setParts(parts.map((part) => (part.id === editPartId ? { id: editPartId, name: partName, thumbnail: partThumbnail } : part)));
      updatePart(editPartId, { name: partName, thumbnail: partThumbnail });
    } else {
      // Add a new part
      setParts([...parts, createPart(partName, partThumbnail)]);
      addNewPart({ name: partName, thumbnail: partThumbnail });
    }
    handleClose();
  };

  const handleSearchChange = (event, value) => {
    if (value) {
      setSearch(value);
      setPartName(value.name);
      setPartThumbnail(value.thumbnail);

      // Filter the parts based on the selected part
      const filteredParts = parts.filter((part) => part.name === value.name);
      setParts(filteredParts);
    } else {
      setSearch(null);
      // setPartName('');
      // setPartThumbnail('');

      getParts();
    }
  };

  const handleNameChange = (event) => {
    setPartName(event.target.value);
  };

  const handleThumbnailChange = (event) => {
    setPartThumbnail(event.target.value);
  };

  const addNewPart = async (partData) => {
    try {
      const partsCollectionRef = collection(db, 'parts');

      // Add a new document to the "parts" collection
      const newPartRef = await addDoc(partsCollectionRef, partData);

      console.log('New part added with ID: ', newPartRef.id);
      getParts();
      notify('success', 'Part added successfully', partData.name);
      // You can optionally handle success or display a success message here
    } catch (error) {
      console.error('Error adding part: ', error);
      // You can handle errors or display an error message here
    }
  };

  const updatePart = async (partId, updatedData) => {
    try {
      const partDocRef = doc(db, 'parts', partId);

      // Update the document with the provided data
      await updateDoc(partDocRef, updatedData);

      console.log('Part updated successfully!');
      notify('success', 'Part updated successfully', partId);
      // You can optionally handle success or display a success message here
    } catch (error) {
      console.error('Error updating part: ', error);
      // You can handle errors or display an error message here
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedParts = parts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom="16px"
        marginTop="30px"
        className="mx-2"
      >
        <Autocomplete
          options={parts}
          getOptionLabel={(part) => part.name}
          value={search}
          onChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Part"
            />
          )}
          style={{ marginRight: '16px', width: '70%' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
          sx={{
            backgroundColor: '#ED5521',
            fontFamily: 'Montserrat',
            '&:hover': {
              backgroundColor: 'rgba(237, 85, 33, 0.7)'
            }
          }}
        >
          New Part
        </Button>
      </Box>
      <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: '100vh' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedParts.map((part) => (
                <TableRow key={part.id}>
                  <TableCell>{part.name}</TableCell>
                  <TableCell>
                    {part.thumbnail ? (
                      <img
                        height="40px"
                        width="40px"
                        src={part.thumbnail}
                        alt="Thumbnail"
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleOpen(part.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(part.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Dialog
                      open={confirmDeleteId === part.id}
                      onClose={() => setConfirmDeleteId(null)}
                    >
                      <DialogTitle>Confirm Delete</DialogTitle>
                      <DialogContent>
                        <DialogContentText>Are you sure you want to delete this part?</DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setConfirmDeleteId(null)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleConfirmDelete}
                          color="primary"
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={parts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{editPartId ? 'Edit Part' : 'New Part'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{editPartId ? 'Edit the details of the part.' : 'Enter the details of the new part.'}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={partName}
            onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            label="Thumbnail"
            type="text"
            fullWidth
            value={partThumbnail}
            onChange={handleThumbnailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function createPart(name, thumbnail) {
  return {
    id: Math.floor(Math.random() * 10000), // Random ID for the example
    name,
    thumbnail
  };
}

export default Parts;
