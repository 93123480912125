import React, { useEffect, useState } from 'react';
import { doc, updateDoc, arrayUnion, getDoc, collection, addDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../../api/firebase';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  InputLabel,
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TablePagination
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { notify } from '../../Utils/notification';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [parts, setParts] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [selectedPartsWithFormulas, setSelectedPartsWithFormulas] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [selectedInputs, setSelectedInputs] = useState([]);
  const [open, setOpen] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [partName, setPartName] = useState('');
  const [category, setCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [productThumbnail, setProductThumbnail] = useState('');
  const [search, setSearch] = useState(null);
  const [refreshProducts, setRefreshProducts] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [slicedProducts, setSlicedProducts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cloneProductData, setCloneProductData] = useState(null);

  useEffect(() => {
    // Get all parts and inputs from Firestore (async functions)
    const fetchData = async () => {
      await getParts();
      await getInputs();
    };

    fetchData();
    getProducts();
  }, []);

  useEffect(async () => {
    await getProducts();
    setRefreshProducts(false);
  }, [refreshProducts, page]);

  // Get all parts from Firestore (async function)
  const getParts = async () => {
    const partsCollectionRef = collection(db, 'parts');
    const partsSnapshot = await getDocs(partsCollectionRef);
    const partsList = partsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setParts(partsList);
  };

  // Get all inputs from Firestore (async function)
  const getInputs = async () => {
    const inputsCollectionRef = collection(db, 'inputs');
    const inputsSnapshot = await getDocs(inputsCollectionRef);
    const inputsList = inputsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setInputs(inputsList);
  };

  const getProducts = async () => {
    const productsCollectionRef = collection(db, 'products');
    const productsSnapshot = await getDocs(productsCollectionRef);
    const productsList = productsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    const slicedProducts = productsList.slice(startIndex, endIndex);

    setProducts(productsList); // Update the full products list
    setSlicedProducts(slicedProducts); // Update the sliced products list for pagination
  };

  const handleSelectedInputsChange = (event) => {
    const {
      target: { value }
    } = event;
    console.log(value);
    setSelectedInputs(typeof value === 'string' ? value.split(',') : value);
    console.log(typeof value === 'string' ? value.split(',') : value);
  };

  const handleOpen = (product, clone = false) => {
    if (product && clone) {
      setCloneProductData({
        name: product.name,
        thumbnail: product.thumbnail,
        parts: [...product.parts],
        inputs: [...product.inputs]
      });
    } else {
      setCloneProductData(null); // Reset cloneProductData
    }
    setOpen(true);
    setEditProductId(product ? product.id : null);
    setProductName(clone ? product.name : product ? product.name : '');
    setProductThumbnail(clone ? product.thumbnail : product ? product.thumbnail : '');
    setSelectedParts(clone ? [...product.parts] : product ? product.parts : []);
    setSelectedInputs(clone ? [...product.inputs] : product ? product.inputs : []);
  };

  const handleClose = () => {
    setOpen(false);
    setEditProductId(null);
    setProductName('');
    setProductThumbnail('');
    setSelectedParts([]);
    //setSelectedInputs([]);

    setRefreshProducts(true); // Trigger a refresh of the products list
    setCloneProductData(null); // Clear the cloned product data
    closeConfirmDeleteDialog(); // Close the confirmation dialog
  };

  const handleDelete = (id) => {
    setParts(parts.filter((part) => part.id !== id));
  };

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleThumbnailChange = (event) => {
    setProductThumbnail(event.target.value);
  };

  const handleClickRemovePart = (partToRemove) => {
    console.log(partToRemove);
    setSelectedParts(selectedParts.filter((part) => part !== partToRemove));
  };

  const handleMouseDownPart = (event) => {
    event.preventDefault();
  };

  const handleFormulaChange = (partToEdit, formula) => {
    console.log(selectedParts.map((part) => (part.id === partToEdit.id ? { ...partToEdit, formula: formula } : part)));
    //setSelectedParts(selectedParts.map((part) => (part.id === partToEdit.id ? { ...partToEdit, formula: formula } : part)));

    // Find the part in the selectedParts array and update the formula and return the updated array
    const updatedSelectedParts = selectedParts.map((part) => (part.id === partToEdit.id ? { ...partToEdit, formula: formula } : part));
    console.log(updatedSelectedParts);
    setSelectedParts(updatedSelectedParts);
  };

  const addNewProduct = async (productData) => {
    try {
      const productsCollectionRef = collection(db, 'products');

      // Add a new document to the "parts" collection
      const newProductRef = await addDoc(productsCollectionRef, productData);

      console.log('New product added with ID: ', newProductRef.id);
      // You can optionally handle success or display a success message here
      setOpen(false);
      setRefreshProducts(true);
      notify('success', 'Product added successfully', productData.name);
      setCloneProductData(null); // Clear the cloned product data
    } catch (error) {
      console.error('Error adding product: ', error);
      // You can handle errors or display an error message here
    }
  };

  const updateProduct = async (productData) => {
    try {
      let updatedProductData = productData;
      if (cloneProductData) {
        updatedProductData = cloneProductData; // Use the clone product data instead
      }

      const productRef = doc(db, 'products', editProductId);

      // Set the "capital" field of the city 'DC'
      await updateDoc(productRef, updatedProductData);

      console.log('Product updated with ID: ', editProductId);
      // You can optionally
      setOpen(false);
      setRefreshProducts(true);
      notify('success', 'Product updated successfully', productData.name);
      setCloneProductData(null); // Clear the cloned product data
    } catch (error) {
      console.error('Error updating product: ', error);
      // You can handle errors or display an error message here
    }
  };

  const deleteProduct = async (id) => {
    openConfirmDeleteDialog(); // Open the confirmation dialog
    setEditProductId(id); // Set the ID of the product to be deleted
  };

  const handleSearchChange = (event, value) => {
    if (value) {
      setSearch(value);
      setProductName(value.name);
      setProductThumbnail(value.thumbnail);

      // Filter the full list of products based on the selected product
      const filteredProducts = products.filter((product) => product.name === value.name);
      setSlicedProducts(filteredProducts); // Update the sliced products with the filtered products
    } else {
      setSearch(null);
      setProductName('');
      setProductThumbnail('');

      // Reset the sliced products to the full list of products
      //setSlicedProducts(products);

      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
      const slicedProducts = products.slice(startIndex, endIndex);

      // Update the full products list
      setSlicedProducts(slicedProducts); // Update the sliced products list for pagination
    }
  };

  const openConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(true);
    setCloneProductData(null); // Clear the cloned product data
  };

  const closeConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDeleteConfirmed = async () => {
    try {
      await deleteDoc(doc(db, 'products', editProductId));
      handleClose(); // Close the dialog and trigger a refresh of the products list
      setRefreshProducts(true);
      notify('success', 'Product deleted successfully', editProductId);
    } catch (error) {
      console.error('Error deleting product:', error);
      // Handle the error as desired
    }
  };

  //console.log({ name: productName, thumbnail: productThumbnail, parts: selectedParts, inputs: selectedInputs });
  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom="16px"
        marginTop="30px"
        className="mx-2"
      >
        {/* Apply center alignment */}
        <Autocomplete
          options={products}
          getOptionLabel={(product) => product.name}
          value={search}
          onChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product"
            />
          )}
          style={{ marginRight: '16px', width: '70%' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
          sx={{
            backgroundColor: '#ED5521',
            fontFamily: 'Montserrat',
            '&:hover': {
              backgroundColor: 'rgba(237, 85, 33, 0.7)'
            }
          }}
        >
          New Product
        </Button>
      </Box>
      <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="overflow-auto">
              {slicedProducts.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    <img
                      height="40px"
                      width="60px"
                      src={product.thumbnail}
                    />
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <IconButton
                      aria-label="clone"
                      onClick={() => handleOpen(product, true)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleOpen(product)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteProduct(product.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset the page when the rows per page is changed
          }}
        />
      </Box>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{editProductId ? (cloneProductData ? 'Clone Product' : 'Edit Product') : 'New Product'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{editProductId ? 'Edit the details of the product.' : 'Enter the details of the new product.'}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={productName}
            onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            label="Thumbnail"
            type="text"
            fullWidth
            value={productThumbnail}
            onChange={handleThumbnailChange}
          />
          {/* <InputLabel id="demo-multiple-name-label">Inputs</InputLabel>
          <Select
            fullWidth
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedInputs}
            onChange={handleSelectedInputsChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {inputs.map((input) => (
              <MenuItem
                key={input.id}
                value={input}
                selected={selectedInputs.indexOf(input) !== -1}
              >
                {input.name}
              </MenuItem>
            ))}
          </Select> */}

          <Autocomplete
            className="mt-3"
            fullWidth
            multiple
            value={selectedParts}
            onChange={(event, newValue) => setSelectedParts(newValue)}
            options={parts}
            getOptionLabel={(part) => part.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Parts"
              />
            )}
          />
          <DialogContentText className="mt-5">{'Formulas'}</DialogContentText>
          {selectedParts.map((part) => (
            <FormControl
              key={part.id}
              sx={{ mt: 3, width: 'full', display: 'flex', flexDirection: 'column' }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">{part.name}</InputLabel>
              <OutlinedInput
                key={part.id}
                id="outlined-adornment-password"
                margin="dense"
                label={part.name}
                type="text"
                value={selectedParts.find((p) => p.id === part.id).formula}
                onChange={(e) => handleFormulaChange(part, e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickRemovePart(part)}
                      onMouseDown={handleMouseDownPart}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          {!cloneProductData && !editProductId && (
            <Button
              onClick={() => addNewProduct({ name: productName, thumbnail: productThumbnail, parts: selectedParts, inputs: selectedInputs })}
              color="primary"
            >
              Save
            </Button>
          )}
          {editProductId && !cloneProductData && (
            <Button
              onClick={() => updateProduct({ name: productName, thumbnail: productThumbnail, parts: selectedParts, inputs: selectedInputs })}
              color="primary"
            >
              Update
            </Button>
          )}
          {cloneProductData && (
            <Button
              onClick={() => addNewProduct({ name: productName, thumbnail: productThumbnail, parts: selectedParts, inputs: selectedInputs })}
              color="primary"
            >
              Clone
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        aria-labelledby="confirm-dialog-title"
        sx={{ zIndex: 9999 }}
      >
        <DialogTitle id="confirm-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this product?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmed}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Rest of the code...

export default Products;
