import { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Tab, Tabs } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  const handleTabClick = (route, index) => {
    setValue(index);
    navigate(route);
  };

  useEffect(() => {
    // Set the active tab based on the current URL path
    switch (location.pathname) {
      case '/':
        setValue(0);
        break;
      case '/products':
        setValue(1);
        break;
      case '/parts':
        setValue(2);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location]);

  return (
    <AppBar
      //position="static"
      className="relative top-0"
      style={{ backgroundColor: '#ED5521', fontFamily: 'Montserrat' }}
    >
      <Tabs
        value={value}
        TabIndicatorProps={{
          style: { backgroundColor: '#FFF' }
        }}
        sx={{
          '& .MuiTabs-flexContainer': {
            justifyContent: 'space-evenly'
          },
          '& .MuiTab-root': {
            fontFamily: 'Montserrat'
          }
        }}
      >
        <Tab
          label="Home"
          onClick={() => handleTabClick('/', 0)}
          style={value === 0 ? { color: '#FFF' } : {}}
        />
        <Tab
          label="Products"
          onClick={() => handleTabClick('/products', 1)}
          style={value === 1 ? { color: '#FFF' } : {}}
        />
        <Tab
          label="Parts"
          onClick={() => handleTabClick('/parts', 2)}
          style={value === 2 ? { color: '#FFF' } : {}}
        />
      </Tabs>
    </AppBar>
  );
};

export default Header;
