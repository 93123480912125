import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, useNavigate } from 'react-router-dom';
import { query, collection, where } from 'firebase/firestore';
import { auth, db } from './api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import NavBar from './components/NavBar.js';
import PrivateRoute from './components/PrivateRoute.js';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Logout from './Pages/Auth/Logout';
import Home from './Pages/Home/Home';
import Products from './Pages/Products/Products';
import Parts from './Pages/Parts/Parts';
import { ReactNotifications } from 'react-notifications-component';

export default function App() {
  const [user, loading, error] = useAuthState(auth);
  const [value, loadingCollection, collectionError] = useCollection(query(collection(db, 'users'), where('uid', '==', user && user.uid)), {});

  useEffect(() => {
    if (loading && !user) return;

    if (user && value) {
      console.log(value);

      //dispatch(setUser({ ...value.docs[0].data(), ref: value.docs[0].ref }));
      //dispatch(setUser({ ...value.docs[0].data() }));
    }
  }, [loadingCollection]);

  return (
    <div>
      <ReactNotifications />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <NavBar />
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/products"
            element={
              <PrivateRoute>
                <NavBar />
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            path="/parts"
            element={
              <PrivateRoute>
                <NavBar />
                <Parts />
              </PrivateRoute>
            }
          />

          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            exact
            path="/register"
            element={<Register />}
          />
          {/* <Route
            path='/reset-password'
            element={<ResetPassword />}
          /> */}
          <Route
            path="/logout"
            element={<Logout />}
          />
        </Routes>
      </Router>
    </div>
  );
}
