import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { Store } from 'react-notifications-component';

export let notification = {
  title: 'Success:',
  message: 'configure',
  type: 'success',
  container: 'bottom-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true
  }
};

export const notify = (type, title, message, container) =>
  Store.addNotification({
    ...notification,
    title: title,
    message: message,
    type: type,
    container: container ? container : 'bottom-right'
  });
