import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { logout } from '../../api/auth';
import { notify } from '../../Utils/notification';

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate('/login');
  }, []);

  return <div>Logging out</div>;
}
