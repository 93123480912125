async function getContactsWorkOrders(contact_id, api_key) {
  const response = await fetch(
    `https://delicate-mud-81d7.nanosolutions.workers.dev/?https://app.jobnimbus.com/api1/workorders?filter={"must":{"term":{"related.id":"${contact_id}"}}}`,
    {
      headers: {
        accept: 'application/json, text/javascript, */*; q=0.01',
        'accept-language': 'en-US,en;q=0.9',
        authorization: `bearer ${api_key}`,
        'content-type': 'application/json'
      },
      referrer: 'https://webappui.jobnimbus.com/',
      referrerPolicy: 'strict-origin-when-cross-origin',
      method: 'GET'
      //mode: 'cors'
      //credentials: 'include'
    }
  )
    .then((data) => data.json())
    .catch((err) => console.log(err));
  console.log(response.results);
  console.log(response.count);
  return response.results;
}

export { getContactsWorkOrders };
