import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import './index.css';
import 'react-notifications-component/dist/theme.css';
//import 'bootstrap';
import 'tw-elements';
import App from './App';
// import { ToolProvider } from './contexts/ToolContext';
// import { AuthProvider } from './contexts/AuthContext';
// import { SketchProvider } from './contexts/SketchContext';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('main')
);
