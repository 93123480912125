import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../api/firebase';
import { useEffect } from 'react';

export default function PrivateRoute({ children }) {
  const [user, loading, error] = useAuthState(auth);
  //const navigate = useNavigate();

  //if (user) {return  <Component {...props} />} else navigate('/login');
  //return children;
  if (loading) return null;
  return user ? children : <Navigate to="/login" />;
}
