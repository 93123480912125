import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { doc, updateDoc, arrayUnion, getDoc, collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../api/firebase';
import { Button, Dialog, Collapse, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@mui/material/Autocomplete';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';
import { notify } from '../../Utils/notification';
import { getContactsWorkOrders } from '../../api/jobnimbus';
import moment from 'moment';

const getContacts = async () => {
  try {
    const config = {
      method: 'get',
      url: 'https://delicate-mud-81d7.nanosolutions.workers.dev/?https://app.jobnimbus.com/api1/contacts?size=3000',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `bearer ${process.env.REACT_APP_JOBNIMBUS_API_KEY}`
      }
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

async function createFile(pdfDataUri, fileName, jnid, selectedWorkOrder) {
  try {
    //var base64result = blob;
    var base64result = pdfDataUri.split(',')[1];
    var raw = JSON.stringify({
      data: base64result,
      is_private: false,
      related: [jnid],
      type: 9,
      subtype: 'contact',
      filename: fileName + '.pdf',
      description: 'Material Order',
      date: Date.now(),
      persist: true
    });

    var config = {
      method: 'post',
      url: 'https://delicate-mud-81d7.nanosolutions.workers.dev/?https://app.jobnimbus.com/api1/files',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `bearer ${process.env.REACT_APP_JOBNIMBUS_API_KEY}`
      },
      data: raw
    };

    const response = await axios(config)
      .then((response) => {
        console.log(response.data);
        notify('success', 'Success', 'File successfully uploaded to JobNimbus');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        notify('danger', 'PDF did not upload successfully');
        return error.message;
      });

    return response;
    // notify('success', 'PDF uploaded successfully', fileName);
  } catch (error) {
    console.log(error.message);
    notify('danger', 'PDF failed to upload', error.message);
  }
}

// Helper function to convert a data URI to a Blob
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

const App = () => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [parts, setParts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [linearFeet, setLinearFeet] = useState({});
  const [quantity, setQuantity] = useState({});
  const [corners, setCorners] = useState({});
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [partQuantities, setPartQuantities] = useState({}); // State variable to track part quantities
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  //const [selectedPart, setSelectedPart] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [wastePercentage, setWastePercentage] = useState({});
  const [contactsWorkOrders, setContactsWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState();
  const tableRef = useRef(null);

  useEffect(() => {
    // Get all products from Firestore (async function)

    getProducts();

    // Get all parts from Firestore (async function)

    getParts();

    // Get contacts
    const apiKey = process.env.REACT_APP_JOBNIMBUS_API_KEY; // Replace with your actual API key
    const fetchContacts = async () => {
      try {
        const contactsData = await getContacts(apiKey);
        console.log(contactsData);
        setContacts(contactsData.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchContacts();
  }, []);

  useEffect(() => {
    // Initialize part quantities with default values based on the part formulas for each added product

    const initialPartQuantities = {};
    addedProducts.forEach((product) => {
      initialPartQuantities[product.id] = {};
      product.parts.forEach((part) => {
        let waste = wastePercentage[product.id] ? wastePercentage[product.id] : 0;

        const formulaResult = Math.ceil(eval(eval(part.formula)) * (1 + waste / 100));

        const partQuantity = Number.isNaN(formulaResult) ? 0 : formulaResult;

        //initialPartQuantities[product.id][part.id] = partQuantity;

        initialPartQuantities[product.id][part.id] = partQuantity;
      });
    });
    setPartQuantities(initialPartQuantities);
  }, [addedProducts, linearFeet, corners, wastePercentage, quantity]);

  const getProducts = async () => {
    const productsCollectionRef = collection(db, 'products');
    const productsSnapshot = await getDocs(productsCollectionRef);
    const productsList = productsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setProducts(productsList);
  };

  const getParts = async () => {
    const partsCollectionRef = collection(db, 'parts');
    const partsSnapshot = await getDocs(partsCollectionRef);
    const partsList = partsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setParts(partsList);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleExpandAll = () => {
    setExpanded((prevState) => {
      const allExpanded = Object.values(prevState).some((value) => value === false);
      const updatedState = {};
      Object.keys(prevState).forEach((key) => {
        updatedState[key] = allExpanded;
      });
      return updatedState;
    });
  };

  const handleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleClose = (value) => {
    // window.document.body.style.zoom = 1;
    // document.body.style.transform = 'scale(1)';
    if (value) {
      const uniqueProductId = value.id + uuidv4(); // Generate a unique ID for the added product
      const newProduct = { ...value, id: uniqueProductId };
      setAddedProducts((prevProducts) => [...prevProducts, newProduct]);
      setLinearFeet((prevLinearFeet) => ({ ...prevLinearFeet, [uniqueProductId]: 1 }));
      setQuantity((prevQuantity) => ({ ...prevQuantity, [uniqueProductId]: 1 }));
      setExpanded((prevState) => ({ ...prevState, [uniqueProductId]: true })); // Expand the newly added product
    }
    setOpen(false);
  };

  const handleAutoCompleteChange = async (value) => {
    setSelectedContact(value);

    if (value) {
      const workOrders = await getContactsWorkOrders(value.jnid, process.env.REACT_APP_JOBNIMBUS_API_KEY);
      if (workOrders) setContactsWorkOrders(workOrders);
    }
  };

  const handleRowClick = (id, event) => {
    // Check if the clicked element is within the parts table
    const isWithinPartsTable = event.target.closest('.parts-table');

    // Check if the clicked element is within the dialog
    const isWithinDialog = event.target.closest('.part-dialog');

    // If the clicked element is within the parts table or the dialog, prevent collapsing the row
    if (isWithinPartsTable || isWithinDialog) {
      event.stopPropagation();
      return;
    }

    // Toggle the collapse state of the row
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleDelete = (id) => {
    setAddedProducts(addedProducts.filter((product) => product.id !== id));
  };

  const handleLinearFeetChange = (productId, event) => {
    const { value } = event.target;

    const numericValue = parseFloat(value);
    console.log(numericValue);

    setLinearFeet({ ...linearFeet, [productId]: numericValue });

    // Recalculate quantities based on the part formula
    const updatedPartQuantities = { ...partQuantities };
    const product = addedProducts.find((product) => product.id === productId);

    if (product) {
      product.parts.forEach((part) => {
        const formulaResult = Math.ceil(eval(eval(part.formula)));
        console.log(formulaResult);
        updatedPartQuantities[productId][part.id] = isNaN(formulaResult) ? 0 : formulaResult;
      });
    }

    setPartQuantities(updatedPartQuantities);
  };

  const handleQuantityChange = (productId, event) => {
    const { value } = event.target;

    const numericValue = parseFloat(value);
    console.log(numericValue);

    setQuantity({ ...quantity, [productId]: numericValue });
    console.log({ ...quantity, [productId]: numericValue });
    // Recalculate quantities based on the part formula

    const updatedPartQuantities = { ...partQuantities };
    const product = addedProducts.find((product) => product.id === productId);

    if (product) {
      product.parts.forEach((part) => {
        // const formulaResult = Math.ceil();
        // console.log(formulaResult);
        updatedPartQuantities[productId][part.id] = updatedPartQuantities[productId][part.id] * numericValue;
        console.log({ part: updatedPartQuantities[productId][part.id], value: updatedPartQuantities[productId][part.id] * numericValue });
      });
    }

    setPartQuantities(updatedPartQuantities);
  };

  const handlePartQuantityChange = (productId, partId, event) => {
    const { value } = event.target;
    setPartQuantities((prevPartQuantities) => ({
      ...prevPartQuantities,
      [productId]: {
        ...prevPartQuantities[productId],
        [partId]: parseInt(value) || 0 // Convert the value to an integer, or 0 if the value is invalid
      }
    }));
  };

  const handleCornerChange = (productId, event) => {
    const { value } = event.target;
    setCorners({ ...corners, [productId]: value });

    // Recalculate quantities based on the part formula
    const updatedPartQuantities = { ...partQuantities };
    const product = addedProducts.find((product) => product.id === productId);

    if (product) {
      product.parts.forEach((part) => {
        const formulaResult = Math.ceil(eval(eval(part.formula)));
        updatedPartQuantities[productId][part.id] = isNaN(formulaResult) ? 0 : formulaResult;
      });
    }

    setPartQuantities(updatedPartQuantities);
  };

  const exportPDF = (type) => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3, or A4
    const orientation = 'portrait'; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    //const title = 'Material Order';

    const headers = ['Product Name', '', '', ''];

    const data = [];

    addedProducts.forEach((product) => {
      //const productRow = [{ content: product.name, styles: { fontStyle: 'bold' } }, '', linearFeet[product.id] || 0, corners[product.id] || 0];
      const productRow = [{ content: product.name, styles: { fontStyle: 'bold' } }, '', '', ''];
      data.push(productRow); // Add the product row to the data array

      if (expanded[product.id]) {
        const subheaders = [
          { content: '', styles: { fillColor: '#818589' } }, // Empty space for the first column
          { content: 'Part Name', styles: { fillColor: '#818589', textColor: [255, 255, 255] } }, // Custom fill color for subheader row
          { content: 'Quantity', styles: { fillColor: '#818589', textColor: [255, 255, 255] } }, // Custom fill color for subheader row
          { content: '', styles: { fillColor: '#818589' } }
        ];
        const parts = product.parts.map((part) => ['', part.name, partQuantities[product.id][part.id] || 0]);
        data.push(subheaders, ...parts); // Add the subheader and parts to the data array
      }
    });

    let content = {
      startY: 100,
      head: [headers],
      body: data
    };

    // Adjust the table width to move the subheader one column to the right
    const tableWidth = doc.internal.pageSize.getWidth() - marginLeft * 2;
    const columnWidths = [tableWidth * 0.2, tableWidth * 0.2, tableWidth * 0.2, tableWidth * 0.2];
    content.headStyles = { fillColor: [237, 85, 33] }; // Custom fill color for header row
    content.bodyStyles = { columnWidth: columnWidths };

    //doc.text(title, marginLeft, 40);

    // Add the black bar at the top
    doc.setFillColor(0);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, 'F');

    // Add the text "Material Order" on top of the black bar

    const getStringWidthWithSpacing = (str, letterSpacing, fontSize) => {
      let width = 0;
      for (let i = 0; i < str.length; i++) {
        width += (doc.getStringUnitWidth(str[i]) * fontSize) / doc.internal.scaleFactor;
        if (i < str.length - 1) {
          width += letterSpacing;
        }
      }
      return width;
    };

    const text = 'MATERIAL ORDER';

    const letterSpacing = 2; // Letter spacing in pixels
    const fontSize = 12;
    const textX = (doc.internal.pageSize.getWidth() - getStringWidthWithSpacing(text, letterSpacing, fontSize)) / 2;
    const textY = 14;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(fontSize);
    doc.setTextColor(255);
    let currentX = textX;
    for (let i = 0; i < text.length; i++) {
      doc.text(text[i], currentX, textY);
      currentX += (doc.getStringUnitWidth(text[i]) * fontSize) / doc.internal.scaleFactor + letterSpacing;
    }

    //doc.text(title, 40, 40); // Title
    //doc.text('Header Section', doc.internal.pageSize.getWidth() - marginLeft, 30, { align: 'right' }); // Header section on top right
    const logoUrl = 'https://uploads-ssl.webflow.com/62c785fe02fc516f0ee97490/645156ad8f656580e551f745_texas-select-fencing-logo-black-p-500.png'; // Replace with the URL or local path to your company logo image
    doc.addImage(logoUrl, 'PNG', doc.internal.pageSize.getWidth() - 140, 30, 100, 50); // Adjust the positioning and width of the logo as needed

    // Add the section at the top left corner
    const sectionData = [['Texas Select Fencing'], ['2170 Collins Road'], ['#802'], ['Denton, TX 76208'], ['(214) 558-9169']];

    const sectionX = marginLeft;
    const sectionY = 35; // Adjust the vertical position as needed
    const sectionZ = 70;
    const sectionLineHeight = 12;

    sectionData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const cellY = sectionY + rowIndex * sectionLineHeight;
        doc.setTextColor(0);
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');
        doc.text(cell, sectionX, cellY);
      });
    });

    // Add the customer information column
    const customerData = [
      [{ content: 'CUSTOMER:', styles: { fontStyle: 'bold' } }],
      [selectedContact.display_name],
      [selectedContact.address_line1],
      [`${selectedContact.city}, ${selectedContact.state_text} ${selectedContact.zip || ''}`]
    ];

    const workOrderData = [
      [{ content: 'PROJECT START DATE:', styles: { fontStyle: 'bold' } }],
      [moment.unix(selectedWorkOrder.date_start).format('dddd, MMMM Do YYYY')],
      [`Work Order ID: ${selectedWorkOrder.recid}`]
    ];

    const customerX = sectionX + 100;
    const customerY = 35; // Adjust the vertical position as needed

    customerData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const cellY = sectionY + rowIndex * sectionLineHeight;
        doc.setTextColor(0); // Set text color to black
        if (cell.styles && cell.styles.fontStyle === 'bold') {
          doc.setFont('helvetica', 'bold');
        } else {
          doc.setFont('helvetica', 'normal');
        }
        doc.text(cell.content || cell, customerX, cellY);
      });
    });

    workOrderData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const cellY = sectionY + rowIndex * sectionLineHeight;
        doc.setTextColor(0); // Set text color to black
        if (cell.styles && cell.styles.fontStyle === 'bold') {
          doc.setFont('helvetica', 'bold');
        } else {
          doc.setFont('helvetica', 'normal');
        }
        doc.text(cell.content || cell, customerX + 100, cellY);
      });
    });

    autoTable(doc, content);

    // Add page number
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      doc.setPage(i);
      doc.setTextColor(0);
      doc.setFontSize(10);
      doc.text(`Page ${i + 1} of ${pageCount}`, doc.internal.pageSize.getWidth() - 50, doc.internal.pageSize.getHeight() - 10);
    }

    if (type === 'download') {
      console.log(selectedWorkOrder);
      return doc.save(`MO - ${selectedContact.address_line1} - ${moment.unix(selectedWorkOrder.date_start).format('MM/DD/YYYY')}`);
    } else if (type === 'upload') {
      //const pdfBlob = doc.output('blob');
      const pdfDataUri = doc.output('datauristring');
      console.log(pdfDataUri);
      return createFile(
        pdfDataUri,
        `MO - ${selectedContact.address_line1} - ${moment.unix(selectedWorkOrder.date_start).format('M-DD-YYYY')}`,
        selectedContact.jnid,
        selectedWorkOrder
      );
    }
  };

  const handleDeletePart = (productId, partId) => {
    setAddedProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product) => {
        if (product.id === productId) {
          const updatedParts = product.parts.filter((part) => part.id !== partId);
          return { ...product, parts: updatedParts };
        }
        return product;
      });
      return updatedProducts;
    });
  };

  const handleAddPart = (selectedPart, selectedProductIndex) => {
    console.log('adding part');
    console.log(selectedPart, selectedProductIndex);
    if (selectedPart && selectedProductIndex !== null) {
      setAddedProducts((prevProducts) => {
        const updatedProducts = [...prevProducts];
        const selectedProduct = updatedProducts[selectedProductIndex];
        const newPart = {
          id: selectedPart.id,
          name: selectedPart.name,
          thumbnail: selectedPart.thumbnail,
          quantity: 0 // Set the initial quantity for the new part
        };
        selectedProduct.parts.push(newPart);
        return updatedProducts;
      });
      //setSelectedPart(null); // Reset the selected part
      setDialogOpen(false); // Close the dialog
    }
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleWastePercentageChange = (productId, event) => {
    const { value } = event.target;
    setWastePercentage({ ...wastePercentage, [productId]: value });

    // Recalculate quantities based on the part formula
    const updatedPartQuantities = { ...partQuantities };
    const product = addedProducts.find((product) => product.id === productId);

    if (product) {
      product.parts.forEach((part) => {
        const formulaResult = Math.ceil(eval(eval(part.formula).replace(/wastePercentage/g, value)));
        updatedPartQuantities[productId][part.id] = isNaN(formulaResult) ? 0 : formulaResult;
      });
    }

    setPartQuantities(updatedPartQuantities);
  };

  const handleWorkOrderSelectChange = (e) => {
    setSelectedWorkOrder(e.target.value);
  };

  return (
    <Box maxHeight="100vh">
      <div className="justify-left">
        <Box
          //display="flex"
          //flexDirection="column" // Stack buttons vertically
          alignItems="center"
          justifyContent="left"
          marginBottom="16px"
          marginTop="30px"
          className="mx-2 flex-col md:flex md:flex-row md:justify-left md:mx-2"
        >
          <Autocomplete
            id="contact-search"
            options={contacts}
            getOptionLabel={(option) => option.display_name}
            //style={{ width: '40%' }} // Full width for autocomplete
            className="w-full md:w-1/4 mr-10 min-w-[300px]"
            onChange={(event, newValue) => {
              handleAutoCompleteChange(newValue);
              console.log(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Contacts"
                variant="outlined"
              />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            sx={{
              //marginTop: '16px', // Add margin to separate buttons
              backgroundColor: '#ED5521',
              fontFamily: 'Montserrat',
              '&:hover': {
                backgroundColor: 'rgba(237, 85, 33, 0.7)'
              }
            }}
            className="w-full mt-4 md:w-auto md:mt-0 md:mx-5 mx-0"
          >
            Add Product
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleExpandAll}
            sx={{
              //marginTop: '16px', // Add margin to separate buttons
              backgroundColor: '#ED5521',
              fontFamily: 'Montserrat',
              '&:hover': {
                backgroundColor: 'rgba(237, 85, 33, 0.7)'
              }
            }}
            className="w-full mt-4 md:w-auto md:mt-0 md:mx-5 mx-0"
          >
            {Object.values(expanded).every((value) => value === true) ? 'Collapse All' : 'Expand All'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportPDF('upload')}
            sx={{
              //marginTop: '16px', // Add margin to separate buttons
              backgroundColor: '#ED5521',
              fontFamily: 'Montserrat',
              '&:hover': {
                backgroundColor: 'rgba(237, 85, 33, 0.7)'
              }
            }}
            className="w-full mt-4 md:w-auto md:mt-0 md:mx-5 mx-0"
          >
            Upload PDF
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportPDF('download')}
            sx={{
              //marginTop: '16px', // Add margin to separate buttons
              backgroundColor: '#ED5521',
              fontFamily: 'Montserrat',
              '&:hover': {
                backgroundColor: 'rgba(237, 85, 33, 0.7)'
              }
            }}
            className="w-full mt-4 md:w-auto md:mt-0 md:mx-5 mx-0"
          >
            Download PDF
          </Button>
        </Box>
        <Box
          alignItems="left"
          justifyContent="left"
          marginBottom="16px"
          marginTop="30px"
          className={`mx-2 flex-col md:flex md:flex-row md:justify-left md:mx-2`}
        >
          <FormControl
            sx={{ justifyContent: 'left' }}
            className={`w-full md:w-[300px] ${!selectedContact ? 'hidden' : ''} `}
          >
            <InputLabel id="demo-simple-select-autowidth-label">Work Order</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={selectedWorkOrder}
              onChange={handleWorkOrderSelectChange}
              autoWidth
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {contactsWorkOrders.map((e, index) => (
                <MenuItem value={e}>{e.recid}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Dialog
          open={open}
          onClose={() => handleClose(null)}
        >
          <Autocomplete
            id="combo-box-demo"
            options={products}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            onChange={(event, newValue) => {
              handleClose(newValue);
            }}
            renderInput={(params) => (
              <TextField
                className="text-[16px]"
                {...params}
                label="Product"
                variant="outlined"
              />
            )}
          />
        </Dialog>
        <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)', marginTop: '30px' }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              ref={tableRef}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">Parts</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Linear Feet</TableCell>
                  <TableCell align="right">Corners</TableCell>
                  <TableCell align="right">Waste %</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedProducts.map((product, index) => {
                  //product.id = product.id + uuidv4();
                  return (
                    <React.Fragment key={product.id}>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={(e) => handleRowClick(product.id, e)}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="font-extrabold"
                          sx={{
                            maxWidth: '150px', // Set the maximum width for truncation
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {product.name}
                        </TableCell>
                        <TableCell align="right">Parts</TableCell>
                        <TableCell align="right">
                          <TextField
                            className={`max-w-[70px] ${product.name.toLowerCase().includes('walk gate') ? '' : 'hidden'}`}
                            type="number"
                            value={quantity[product.id] || ''}
                            onChange={(event) => handleQuantityChange(product.id, event)}
                            onClick={(event) => event.stopPropagation()}
                            inputProps={{ min: 0 }}
                            //defaultValue={0}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            className={`max-w-[100px] ${product.name.toLowerCase().includes('walk gate') ? 'hidden' : ''}`}
                            type="number"
                            value={linearFeet[product.id] || ''}
                            onChange={(event) => handleLinearFeetChange(product.id, event)}
                            onClick={(event) => event.stopPropagation()}
                            inputProps={{ min: 0 }}
                            //defaultValue={0}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            className={`max-w-[70px] ${product.name.toLowerCase().includes('walk gate') ? 'hidden' : ''}`}
                            type="number"
                            value={corners[product.id] || ''}
                            onChange={(event) => handleCornerChange(product.id, event)}
                            onClick={(event) => event.stopPropagation()}
                            inputProps={{ min: 0 }}
                            //defaultValue={0}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            className="max-w-[70px]"
                            type="number"
                            value={wastePercentage[product.id] || ''}
                            defaultValue={7}
                            onChange={(event) => handleWastePercentageChange(product.id, event)}
                            onClick={(event) => event.stopPropagation()}
                            inputProps={{ min: 0 }}
                            //defaultValue={0}
                          />
                        </TableCell>{' '}
                        {/* New cell */}
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(product.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            key={product.id}
                            onClick={() => handleExpand(product.id)}
                            in={expanded[product.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Table
                                size="small"
                                aria-label="purchases"
                                className="parts-table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="font-semibold w-[200px] md:w-auto">Item Name</TableCell>
                                    <TableCell className="font-semibold">Quantity</TableCell>
                                    <TableCell className="font-semibold">Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {product.parts.map((part) => (
                                    <TableRow
                                      key={part.id}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className="max-w-[20px]"
                                      >
                                        <img
                                          src={part.thumbnail}
                                          width={'30px'}
                                          className="inline mr-2"
                                        />
                                        {part.name}
                                      </TableCell>
                                      <TableCell className="max-w-[50px]">
                                        <TextField
                                          className="w-[90px]"
                                          size="small"
                                          type="number"
                                          value={partQuantities[product.id]?.[part.id] || ''}
                                          //defaultValue={Math.ceil(eval(eval(part.formula)))}
                                          onChange={(event) => handlePartQuantityChange(product.id, part.id, event)}
                                          onClick={(event) => event.stopPropagation()}
                                          inputProps={{ min: 0 }}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        {' '}
                                        {/* New column for delete icon */}
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => handleDeletePart(product.id, part.id)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={(event) => {
                                      setSelectedProductIndex(index);
                                      handleOpenDialog(event);
                                    }}
                                    className="mt-3 ml-3"
                                    sx={{
                                      //marginTop: '16px', // Add margin to separate buttons
                                      backgroundColor: '#ED5521',
                                      fontFamily: 'Montserrat',
                                      '&:hover': {
                                        backgroundColor: 'rgba(237, 85, 33, 0.7)'
                                      }
                                    }}
                                  >
                                    Add Part
                                  </Button>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>

                      <Dialog
                        className="part-dialog"
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        //onClick={(event) => event.stopPropagation()}
                      >
                        <Autocomplete
                          id="part-search"
                          options={parts}
                          style={{ width: 300 }}
                          getOptionLabel={(option) => option.name}
                          //value={selectedPart}
                          onChange={(event, newValue) => {
                            console.log(newValue, selectedProductIndex);
                            handleAddPart(newValue, selectedProductIndex);
                          }}
                          onClick={(event) => event.stopPropagation()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Parts"
                              variant="outlined"
                            />
                          )}
                        />
                        {/* <Button
                          variant="contained"
                          color="primary"
                          className="mt-3"
                          
                        >
                          Add
                        </Button> */}
                      </Dialog>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </Box>
  );
};

export default App;
